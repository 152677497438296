import { NgModule } from '@angular/core';
import { CommonModule , DatePipe  } from '@angular/common';


import { ProductDetailsRoutingModule } from './product-details-routing.module';
import { ProductDetailsComponent } from './product-details.component';
import { ProductDetailsMenuComponent } from './product-details-menu/product-details-menu.component';

import {DropdownModule} from 'primeng/dropdown';
import {TabViewModule} from 'primeng/tabview';
import {CalendarModule} from 'primeng/calendar';
import {DialogModule} from 'primeng/dialog';

import { RatingModule } from 'ng-starrating';

import { AgmCoreModule } from '@agm/core';
import { PagesSharedModule } from '../pagesShared.module';


@NgModule({
  declarations: [ProductDetailsComponent, ProductDetailsMenuComponent],
  imports: [

  CommonModule,
    ProductDetailsRoutingModule,
    DropdownModule,
    TabViewModule,
    CalendarModule,
    DialogModule,
    RatingModule,
    AgmCoreModule,
    PagesSharedModule,

  ],
  providers:[
    DatePipe
  ]
})
export class ProductDetailsModule { }
