import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService} from '../../pages/pages.service'
import { SharedService } from 'src/app/shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/authentication/Services/authentication.service';
import { ProductService } from './product.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  count:number = 0;

  navUrl: string;

  rateNumber: number;




  producrId:any; // product id
  reverseId: number;

  data:any; // product data

  //reservate dates
  reservateDates :any;

  // reveiw data
  reveiwData:any[] = [];;

  //Rules Data
  rulesData:any;

  //imgaes viewer data
  singleImage: string;
  imagesList: string[];
  lat: any = 24.774265;
  long: any = 46.738586;


  //form data opinion client
  disabledSubmitted: boolean = false;

  formData = {
    rate: 0,
    review: '',
    productId: null,
  };

  constructor(
    public translate: TranslationService,
    private route: ActivatedRoute,
    private pagesService: PagesService,
    private spinner: NgxSpinnerService,
    public sharedService: SharedService,
    public authSer: AuthenticationService,
    private producService: ProductService
  ) { }

  ngOnInit() {

    this.spinner.show();
    //catch product id
    this.route.params.subscribe(
      (params: Params) => {
        this.producrId = params['productId'];
        this.reverseId = params['reserveId'];
        this.formData.productId = this.producrId;
        this.producService.productReserveData.productsId = +this.producrId;
        this.producService.productReserveData.reserveId = +this.reverseId;
      }
    )

    //get product data by ID
    this.pagesService.getProductById(this.producrId).subscribe(
       (response: any) => {
         if(response.data) {
          this.data =  response.data;
          console.log('product data => ', this.data);
          this.singleImage ="" ;//response.data?.imagePath[0].imagePath;
         // this.imagesList = response.data?.imagePath.map((img: any) => {return img.imagePath});
          if(this.data.lat != 0) {
            this.lat = this.data.lat;
          }

          if(this.data.long != 0) {
            this.long = this.data.long;
          }

         }
         this.spinner.hide();

      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      }
    );

  }

  onRate(e: any) {
    this.rateNumber = e.newValue;
    this.formData.rate = this.rateNumber;
  }


  handelClickImg(img: string) {
    console.log(img);
    this.singleImage = img;
  }

  //on copy link url
  copyLink() {
    this.navUrl = window.location.href;
    this.sharedService.stringErrorMessage.next('تم نسخ الرابط');
    this.sharedService.errorShowMessage.next(true);
    this.sharedService.hideErrorMessage();
  }


  //handel heart click
  handelHeart() {
    this.data.isFavorite = !this.data.isFavorite;
    const data = {
      productId: this.data.id,
      isFavorite: this.data.isFavorite
    };

    this.pagesService.setProductFavourite(data).subscribe(
      (response: any) => {
        console.log(response)
      },
      (error) => {
        console.log(error);
      }
    );

  }

  //handel changer rent
  handelChangeRent(e: any, type: string) {
    if(e.target.checked && type == 'rent') {
      this.producService.productReserveData.isRent = true;
    } else {
      this.producService.productReserveData.isRent = false
    }
    console.log(this.producService.productReserveData);
  }

  //add eview user 
  handelFormSubmitted(){
    console.log(this.formData);
    const user = this.authSer.getUser();
    const subData = {...this.formData, userName: user.fullName};
    this.pagesService.addProductReview(this.formData).subscribe(
      (response: any) => {
        console.log(response);
        this.reveiwData.push(subData);
        this.formData.review = '';
        this.rateNumber = 0;
        this.disabledSubmitted = false;
      },
      (error) => {
        this.disabledSubmitted = false;
        this.sharedService.stringErrorMessage.next(error.error.error);
        this.sharedService.errorShowMessage.next(true);
        this.sharedService.hideErrorMessage();
         console.log(error);
      }
    );
  }

  //add
  add() {
    this.count +=1;
    this.producService.productReserveData.quantity = this.count;
  }

  //minus
  minus() {
    if(this.count > 0){
      this.count -= 1;
      this.producService.productReserveData.quantity = this.count;
    }
  }


}
