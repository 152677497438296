<div class="container">
  <div [ngClass]="{'caravan': translate.is_arabic, 'caravanEn': !translate.is_arabic}">
    <div class="row">
      <div class="col-lg-9 col-12">
         <div class="header-card">
           <p>{{translate.data.store.detailService}}</p>
         </div>
         <div class="body-card">
            <div class="caravan-card">
              <div class="img-container">
                  <div class="btns-div">
                    <button (click)="handelHeart()" class="btttn heartBtn" *ngIf="!data?.is_Favorite">
                    </button>
                    <button (click)="handelHeart()" class="btttn disheartBtn" *ngIf="data?.is_Favorite">
                    </button>
                    <button class="btttn shareBtn" (click)="copyLink()" ngxClipboard [cbContent]="navUrl">
                    </button>
                  </div>
                  <img src="{{sharedService.ipRootImg + singleImage}}" alt="">
                  <div class="img-sm-container">
                    <img *ngFor="let img of imagesList"
                          src="{{sharedService.ipRootImg + img}}"
                          [ngClass]="{'activeImg': img == singleImage}"
                          alt="" title=""
                          (click)="handelClickImg(img)" />
                  </div>
                </div>
              </div>

            <div class="caravan-details">
              <div class="title-container">
                <img class="twenty" src="../../../assets/caravan/Group 10466.svg" alt="">
                <star-rating *ngIf="data?.rate" [value]="data?.rate"
                  [totalstars]="5"
                  checkedcolor="#ed6c37"
                  uncheckedcolor="rgb(224, 224, 224)"
                  size="24px"
                  [readonly]="true">
                </star-rating>
              </div>
              <p class="caravan-name">{{ data ? data[translate.data.requestdData.genName] : 'N/A' }}</p>
              <p class="caravan-desp">{{translate.is_arabic ? 'الوصف' : 'Descrition'}}</p>
              <p class="caravan-description">{{ data ? data[translate.data.requestdData.genDiscription] : 'N/A' }}</p>
              <p class="prev">{{translate.is_arabic ? 'الكميه' : 'Mount'}}</p>
              <div class="box-container">
                <img src="../../../assets/product/Group 10484.svg" alt="" (click)="add()">
                <p>{{count}}</p>
                <img src="../../../assets/product/Group 10485.svg" alt="" (click)="minus()">
              </div>
              <div class="checkboxss">
                <div class="d-flex">
                  <div class="form-check">
                    <label class="form-check-label" for="flexRadioDefault1">
                      {{translate.data.store.buy}}
                    </label>
                    <input class="form-check-input"
                            name="flexRadioDefault"
                            type="radio"
                            (change)="handelChangeRent($event, 'buy')"
                            style="margin: 2px 5px" id="flexRadioDefault1">
                  </div>
                  <div class="form-check mx-4">
                    <label class="form-check-label" for="flexRadioDefault2">
                      {{translate.data.store.rent}}
                    </label>
                    <input class="form-check-input"
                           type="radio"
                           name="flexRadioDefault"
                           style="margin: 2px 5px"
                           id="flexRadioDefault2" (change)="handelChangeRent($event, 'rent')">
                  </div>
                </div>
              </div>
            </div>
         </div>

         <div class="menu-body">
           <div class="header">
              <ul>
                <li class="active" *ngIf="authSer.isAuth()">{{translate.data.caravanDetail.clientOpinion}}</li>
              </ul>
           </div>
           <div class="body">
            <div class="d-inline">
              <div class="review">
                <div *ngFor="let d of reveiwData">
                  <div class="header-5">
                    <div class="header-5-cont">
                      <div>
                        <p class="fw-bold">{{d?.userName}}</p>
                      </div>
                  </div>
                    <star-rating [value]="d?.rate"
                    [totalstars]="5"
                    checkedcolor="#ed6c37"
                    uncheckedcolor="rgb(224, 224, 224)"
                    size="24px"
                    [readonly]="true">
                  </star-rating>
                  </div>
                  <div class="body-5">
                    <p>{{d?.review}}</p>
                    <div class="more-about">
                      <img src="../../../assets/caravan/Path 3231.svg" alt="">
                    </div>
                  </div>
                </div>

                <div class="review-form" *ngIf="authSer.isAuth()">
                  <p class="review-form-title">{{translate.data.caravanDetail.addComment}}</p>
                  <p class="review-form-sub-title">{{translate.data.caravanDetail.commentDesc}}</p>
                  <!-- <img src="../../../assets/Group 16.svg" alt=""> -->

                  <form class="form g-3 d-flex flex-wrap needs-validation">
                    <div class="form-floating col-12">
                      <textarea class="form-control mb-4 input"
                                placeholder="{{translate.is_arabic ? 'التعليق' :  'Comment' }}"
                                id="floatingTextarea2"
                                [(ngModel)]="formData.review"
                                [ngModelOptions]="{standalone: true}"
                                required
                                style="height: 100px"
                      ></textarea>
                    </div>
                    <div style="width: 100%;">
                      <p>{{translate.data.store.ratting}}</p>
                      <star-rating [value]="rateNumber"
                                  [totalstars]="5"
                                  checkedcolor="#ed6c37"
                                  uncheckedcolor="rgb(224, 224, 224)"
                                  size="24px"
                                  [readonly]="false" (rate)="onRate($event)">
                      </star-rating>
                    </div>
                    <button type="submit" (click)="handelFormSubmitted()" [disabled]="disabledSubmitted">
                      {{translate.is_arabic ? 'ارسال' :  'Send' }}
                    </button>
                  </form>
                </div>

              </div>

            </div>


           </div>
         </div>
      </div>
      <div class="col-3 d-lg-inline d-none">
        <app-product-details-menu [reserveId]="reverseId"></app-product-details-menu>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>

