<div class="d-lg-inline d-none">
  <div class="header">
    <p>{{translate.data.caravanDetail.Reservation}}</p>
  </div>
  <div class="body">



    <div class="titles-cont">
      <p>{{translate.data.caravanDetail.addedTax}}</p>
      <div class="title-cont">
        <p class="title-p1">{{karavan?.taxPerDay}}</p>
        <p>{{translate.data.caravanCard.coin}}</p>
      </div>
    </div>

    <div class="titles-cont">
      <p>{{translate.data.caravanCard.total}}</p>
      <div class="title-cont">
        <p class="title-p1">{{total}}</p>
        <p>{{translate.data.caravanCard.coin}}</p>
      </div>
    </div>
    <button (click)="onhandle()">{{translate.is_arabic ? 'إضافه' : 'ADD'}}</button>
  </div>
</div>

<div class="d-lg-none d-inline mt-2">
  <div class="title-sm">
    <p>{{karavan?.pricePerDay}}</p>
    <p>{{translate.data.caravanCard.coin}}</p>
  </div>
  <div>
    <button class="btn-sm" (click)="onhandle()">{{translate.is_arabic ? 'إضافه' : 'ADD'}}</button>
  </div>
</div>

<!-- -------------------------------------------------- -->

<p-dialog  [(visible)]="display" contentStyleClass="mydialog" [closable]="false">
  <ng-template pTemplate="header" styleClass="header-img">
    <div class="header-img">
      <img (click)="hideDialog()" src="../../../assets/profile/modalformicons/753345.svg" alt="">
    </div>
</ng-template>
  <div class="body-modal">
    <img src="../../../../assets//caravan/Group 67974.png" alt="img">
    <h5>{{translate.data.caravanDetail.regesterFirst}}</h5>
    <p>{{translate.data.caravanDetail.toCanShare}}</p>
  </div>

  <p-footer>
    <div class="footer-modal">
      <button class="main-button" (click)="signIn()">{{translate.is_arabic ? "تسجيل الأن" : "Register Now"}}</button>
      <button class="sub-button" (click)="hideDialog()" >{{translate.is_arabic ? "إلغاء" : "Cancel"}</button>
    </div>
</p-footer>
</p-dialog>



