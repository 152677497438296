import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService} from '../../pages.service';
import {AuthenticationService} from '../../../authentication/Services/authentication.service';
import { DatePipe } from '@angular/common';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-product-details-menu',
  templateUrl: './product-details-menu.component.html',
  styleUrls: ['./product-details-menu.component.scss']
})
export class ProductDetailsMenuComponent implements OnInit {

    //today date
    todayDate = new Date();
    selectedCity: string;
    selectedCountry: string;
    sendData:any = {};
    res:any;
    date1:any ;
    date2:any;
    total:number ;
    display:any;
    city:any;
    country:any;
    noDays:any;
    minDateValue:any ;

    date11:any;
    date12:any;

    dt:any;

    invalidDate: Date[] = [];

    karavan: any;


  @Input('reserveId') reserveId: number;

    showDialog() {
      this.display = true;
    }

    hideDialog() {
      this.display = false;
    }

      constructor( private router: Router,
        public translate: TranslationService,
        private pagesService: PagesService ,
        public authService: AuthenticationService,
        private productService: ProductService,
        private route: ActivatedRoute,
        public datepipe: DatePipe) {

         }

      ngOnInit() {      
        this.total = 0;
      }





      onSelect=(d:any)=>{
        console.log("entered");
        if(this.date1){
          this.minDateValue =this.date1;
        }
        this.getTotal();
      }

      //handel book karavan
      onhandle(){
        if(this.authService.isAuth()) {
          console.log(this.productService.productReserveData);
          this.pagesService.addProductToResrvition(this.productService.productReserveData).subscribe(
            (response: any) => {
              console.log('dd product to reservtions ===> ', response);
              this.router.navigate(['../../../reserve/' + this.reserveId], {relativeTo: this.route});
            },
            (error) => {
              console.log(error);
            }
          )
        } else {
          this.showDialog();
        }
      }

    getNoOfDays=()=>{
      let oneDay = 24 * 60 * 60 * 1000;
      let noDays = Math.round(Math.abs((this.date1 - this.date2)  / oneDay)) + 1;
      return noDays;
    }

    getTotal=()=>{
      let noDay:any = this.getNoOfDays();
      if(noDay){
        this.total = this.karavan.pricePerDay * noDay + this.karavan.taxPerDay;
      } else {
        this.total = 0;
      }
    }

    //redirect to login page
    signIn=()=>{
      this.router.navigate(['auth']);
    }

}
